.actionsListContainer {
    width: 30%;
    height: calc(100vh - 58px);
}

.customActionContainer {
    width: 70%;
    border-left: 0.5px solid #1818338c;
}

.headers {
    padding: 0.75rem 1.75rem;
    background-color: #e4eef6;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 0.75rem 1rem;
}
