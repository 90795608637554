@import 't4b-core-frontend/src/css/colors';

.title {
  position: absolute;
  top: -22px;
  left: 20px;
  font-size: 1rem;
  line-height: 30px;
  padding: 5px;
  background-color: white;
  color: $app-color-text-light;
}

.button,
.buttonAdd {
  background: none;
  border: none;
  transition: 0.2s;
  color: #cfd4d9;
}

.button {
  margin-left: auto;
  span {
    display: block;
    transform: rotate(45deg);
  }
  &:hover {
    color: #e03444 !important;
  }
}

.buttonAdd {
  padding: 0;
  span {
    display: block;
    width: 40px;
  }
  &:hover {
    color: $app-color-text;
  }
}
